module.exports = [{
      plugin: require('/opt/buildhome/repo/node_modules/gatsby-plugin-manifest/gatsby-browser'),
      options: {"plugins":[],"name":"Thoughts of Ankit Popli","start_url":"/","display":"standalone","icon":"src/assets/favicon.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"f7e4178d08a6aa807fd7d5991f22fe2a"},
    },{
      plugin: require('/opt/buildhome/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-73379983-2"},
    },{
      plugin: require('/opt/buildhome/repo/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/buildhome/repo/node_modules/gatsby-plugin-catch-links/gatsby-browser'),
      options: {"plugins":[]},
    }]
